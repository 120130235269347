import * as React from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import { useEffect, useRef, useState } from "react"
import moment from "moment"

import Layout from "../Layout/layout"
import Seo from "../seo"
import AxiosInstance from "../../services/axiosInstance"
import EventModal from "./eventModal"
import { DATE_FORMAT } from "./eventHelper"
import MenuContainer from "./menuContainer"
import TagListView from "./Agenda/tagListView"

const Calendar = ({ addEvent, calendarView }) => {
  if (!["dayGridMonth", "timeGridWeek", "timeGridDay"].includes(calendarView)) {
    calendarView = "dayGridMonth"
  }
  const [showModal, setShowModal] = useState(addEvent)
  const [selectedEventTime, setSelectedEventTime] = useState("")
  const [contentHeight, setContentHeight] = useState(window.innerHeight)
  const calendarRef = useRef()

  const getEvents = async (fetchInfo, successCallback) => {
    try {
      if (fetchInfo) {
        const fromDate = moment(fetchInfo.start).format("YYYY-MM-DD")
        const toDate = moment(fetchInfo.end).format("YYYY-MM-DD")

        const response = await AxiosInstance.get(`/calendar?from_date=${fromDate}&to_date=${toDate}`)
        if (response.status === 200 && successCallback) {
          successCallback(
            response.data.items.map(event => {
              event.start = event.startTime
              event.end = event.endTime
              event.url = event.task ? `/jobs/detail/${event.task.id}` : `/calendar/detail/${event.id}`
              event.extendedProps = {
                duration: moment(event.endTime).diff(moment(event.startTime), "minutes"),
                metaJson: event.metaJson,
              }

              return event
            })
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleEventDrop = eventInfo => {
    AxiosInstance.post(`/calendar/edit/${eventInfo.event.id}`, {
      title: eventInfo.event.title,
      startTime: moment(eventInfo.event.start).format(DATE_FORMAT),
      endTime: moment(eventInfo.event.start)
        .add(eventInfo.event.extendedProps.duration, "minutes")
        .format(DATE_FORMAT),
      eventType: "Service",
    }).catch(function (error) {
      console.log(error)
    })
  }

  useEffect(() => {
    calendarRef.current.getApi().changeView(calendarView)
  })

  const handleDateClick = arg => {
    setSelectedEventTime(moment(arg.date).format(DATE_FORMAT))
    setShowModal(true)
  }

  const renderEventContent = eventInfo => {
    const eventStartTime = eventInfo.event.start.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })

    return (
      <b>
        {eventStartTime.toLowerCase()}{" "}
        <i className={`bi bi-${eventInfo.event.extendedProps.task?.transactionType?.icon}`}></i>{" "}
        {eventInfo.event.title}{" "}
        {eventInfo.event.extendedProps.metaJson.event_tags?.length > 0 && (
          <TagListView
            tags={eventInfo.event.extendedProps.metaJson.event_tags}
            eventId={eventInfo.event.id}
          />
        )}
        {eventInfo.event.extendedProps.task?.tags.length > 0 && (
          <TagListView
            tags={eventInfo.event.extendedProps.task?.tags}
            taskId={eventInfo.event.extendedProps.task?.id}
          />
        )}
      </b>
    )
  }

  useEffect(() => {
    const updateHeight = () => {
      const tabsHeight = document.querySelector(".docsDriveTabs")?.offsetHeight || 0
      const headingHeight = document.querySelector(".headingPartTop")?.offsetHeight || 0
      const newHeight = window.innerHeight - tabsHeight - headingHeight - 90
      setContentHeight(newHeight)
    }

    updateHeight()
    window.addEventListener("resize", updateHeight)
    return () => {
      window.removeEventListener("resize", updateHeight)
    }
  }, [])

  return (
    <Layout>
      <Seo title="Calendar" />
      <div id="right-section" className="h-100">
        <div className="row g-0" id="eventList">
          <div className="col" id="page-urls">
            <MenuContainer setShowModal={setShowModal} page={calendarView} />

            <div className="row">
              <div className="col-lg-12">
                <div className="headingPartTop text-center clearfix">
                  <div className="centerElement">
                    <div className="button-group">
                      <button type="button" className="btn calendarBtn c-prev-button mb-0" aria-label="prev">
                        <i className="fa fa-angle-left" />
                      </button>
                      <h4 className="mx-2 mb-0 currentMonthYear">
                        <strong>&nbsp;</strong>
                      </h4>
                      <button type="button" className="btn calendarBtn c-next-button mb-0" aria-label="next">
                        <i className="fa fa-angle-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              contentHeight={contentHeight}
              initialView={calendarView}
              dateClick={handleDateClick}
              eventContent={renderEventContent}
              headerToolbar={{
                left: "",
                center: "prev,title,next,today",
                right: "",
              }}
              events={(fetchInfo, successCallback, failureCallback) =>
                getEvents(fetchInfo, successCallback, failureCallback)
              }
              editable
              eventDrop={handleEventDrop}
            />
          </div>
          {showModal && (
            <EventModal
              showModal={showModal}
              setShowModal={setShowModal}
              selectedEventTime={selectedEventTime}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Calendar
