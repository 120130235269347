import * as React from "react"

import PrivateRoute from "../../components/privateRoute"
import ReduxWrapper from "../../redux/reduxWrapper"
import Calendar from "../../components/Calendar/calendar"

const CalendarPage = props => {
  let searchUrl = props.location.search
  const params = new URLSearchParams(props.location.search)

  return (
    <PrivateRoute
      component={Calendar}
      location={props.location}
      addEvent={searchUrl.indexOf("addEvent") === 1}
      calendarView={params.get("view")}
    />
  )
}

const WrappedPage = props => <ReduxWrapper element={<CalendarPage {...props} />} />
export default WrappedPage
